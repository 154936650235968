export enum LoaderActions {
  setReloadRefreshButtonVisible = 'SET_RELOAD_REFRESH_BUTTON_VISIBLE',
  setGlobalLoaderVisible = 'SET_GLOBAL_LOADER_VISIBLE',
}

export interface setReloadRefreshButtonVisibleAction {
  type: LoaderActions.setReloadRefreshButtonVisible
  payload: { value: boolean; isDashboard: boolean }
}

export interface SetGlobalLoaderVisibleAction {
  type: LoaderActions.setGlobalLoaderVisible
  payload: { value: boolean }
}

export type ReloadRefreshAction = setReloadRefreshButtonVisibleAction | SetGlobalLoaderVisibleAction

const loaderReducer = (
  state: LoaderState = defaultReloadRefreshState,
  action: ReloadRefreshAction
): LoaderState => {
  switch (action.type) {
    case LoaderActions.setReloadRefreshButtonVisible:
      return {
        ...state,
        isReloadRefreshButtonVisible: action.payload.value,
        isDashboard: action.payload.isDashboard,
      }
    case LoaderActions.setGlobalLoaderVisible:
      return {
        ...state,
        isGlobalLoaderVisible: action.payload.value,
      }
    default:
      return state
  }
}

interface LoaderState {
  isGlobalLoaderVisible: boolean
  isReloadRefreshButtonVisible: boolean
  isDashboard: boolean
}

export const defaultReloadRefreshState = {
  isGlobalLoaderVisible: false,
  isReloadRefreshButtonVisible: false,
  isDashboard: false,
}

export default loaderReducer
