import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import history from '../history'
import activeTradeType from './activeTradeType'
import currency from './currency'
import dataChart from './dataChart'
import dataChartFull from './dataChartFull'
import isAuth from './isAuth'
import isMobile from './isMobile'
import isPaidUserPlan from './isPaidUserPlan'
import limitOrders from './limitOrdersReducer'
import loader from './loaderReducer'
import multichart from './multichart'
import networksConfig from './networksConfig'
import notifications from './notificationsReducer'
import isOnboardingVisible from './onboarding'
import poolActivity from './poolActivity'
import pools from './pools'
import settings from './settingsReducer'
import tokens from './tokens'
import topCryptos from './topCryptos'
import traderProfile from './traderProfileReducer'
import tradingHistory from './tradingHistory'
import txn from './txn'
import ui from './uiReducer'
import user from './userReducer'
import wallet from './walletReducer'

const rootReducer = combineReducers({
  router: connectRouter(history),
  ui,
  tokens,
  currency,
  txn,
  isMobile,
  isPaidUserPlan,
  tradingHistory,
  poolActivity,
  dataChart,
  dataChartFull,
  settings,
  activeTradeType,
  traderProfile,
  pools,
  networksConfig,
  isAuth,
  isOnboardingVisible,
  topCryptos,
  multichart,
  loader,
  user,
  notifications,
  limitOrders,
  wallet,
})

export type State = ReturnType<typeof rootReducer>

export default rootReducer
