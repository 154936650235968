import { ChartDataV3 } from '../model'

export const SET_DATA_CHART_FULL = 'SET_DATA_CHART_FULL'

export interface SetDataChartFullAction {
  type: 'SET_DATA_CHART_FULL'
  dataChartFull: ChartDataV3
}

export type DataChartFullAction = SetDataChartFullAction

const dataChartFull = (
  state: DataChartFullState = defaultDataChartFullState,
  action: DataChartFullAction
): DataChartFullState => {
  switch (action.type) {
    case SET_DATA_CHART_FULL:
      return action.dataChartFull
    default:
      return state
  }
}

export const defaultDataChartFullState = []

export type DataChartFullState = ChartDataV3

export default dataChartFull
