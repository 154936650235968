import { TokenChartSettings } from '../../model'

export const DRAFT_KEY = 'draft'

export const SET_ACTIVE_MULTICHART = 'SET_ACTIVE_MULTICHART'
export const MULTICHART_ADD_CHART = 'MULTICHART_ADD_CHART'
export const MULTICHART_REMOVE_CHART = 'MULTICHART_REMOVE_CHART'
export const MULTICHART_ADD_GROUP = 'MULTICHART_ADD_GROUP'
export const MULTICHART_DELETE_GROUP = 'MULTICHART_DELETE_GROUP'
export const MULTICHART_UPDATE_GROUP = 'MULTICHART_UPDATE_GROUP'
export const MULTICHART_SELECT_GROUP = 'MULTICHART_SELECT_GROUP'
export const RESTORE_MULTICHART = 'RESTORE_MULTICHART'
export const MULTICHART_UPDATE_CHART = 'MULTICHART_UPDATE_CHART'
export const DROP_MULTICHART = 'DROP_MULTICHART'
export const SET_LOADED_MULTICHART = 'SET_LOADED_MULTICHART'

export type MultichartGroup = {
  name: string | typeof DRAFT_KEY
  charts: TokenChartSettings[]
  isActive: boolean
  uuid: string
}

export interface MultichartState {
  isActive: boolean
  isLoaded: boolean
  activeGroup: string
  groups: MultichartGroup[]
}

export interface SetActiveMultichart {
  type: 'SET_ACTIVE_MULTICHART'
  payload: { isActive: boolean }
}

export interface AddGroupMultichart {
  type: 'MULTICHART_ADD_GROUP'
  payload: Partial<MultichartGroup>
}

export interface UpdateGroupMultichart {
  type: 'MULTICHART_UPDATE_GROUP'
  payload: Partial<MultichartGroup>
}

export interface DeleteGroupMultichart {
  type: 'MULTICHART_DELETE_GROUP'
  payload?: string
}

export interface SetActiveGroupMultichart {
  type: 'MULTICHART_SELECT_GROUP'
  payload: string
}

export interface AddChartMultichart {
  type: 'MULTICHART_ADD_CHART'
  payload: TokenChartSettings
}

export interface DeleteChartMultichart {
  type: 'MULTICHART_REMOVE_CHART'
  payload: string
}

export interface UpdateChartMultichart {
  type: 'MULTICHART_UPDATE_CHART'
  payload: TokenChartSettings
}
export interface RestoreMultichart {
  type: 'RESTORE_MULTICHART'
  payload: { groups: MultichartGroup[] }
}
export interface DropMultichart {
  type: 'DROP_MULTICHART'
}
export interface SetLoadedMultichart {
  type: 'SET_LOADED_MULTICHART'
  payload: { isLoaded: boolean }
}

export type MultichartAction =
  | SetActiveMultichart
  | AddChartMultichart
  | DeleteChartMultichart
  | UpdateGroupMultichart
  | DeleteGroupMultichart
  | SetActiveGroupMultichart
  | AddGroupMultichart
  | RestoreMultichart
  | UpdateChartMultichart
  | DropMultichart
  | SetLoadedMultichart

export const defaultDraftGroupState = {
  name: '',
  charts: [],
  isActive: true,
}

export const defaultMultichartState = {
  isActive: false,
  isLoaded: false,
  groups: [],
  activeGroup: '',
} as MultichartState
