export const SET_IS_PAID_USER_PLAN = 'SET_IS_PAID_USER_PLAN'

export interface SetIsPaidUserPlanAction {
  type: 'SET_IS_PAID_USER_PLAN'
  isPaidUserPlan: boolean
}

export type PaidUserPlanAction = SetIsPaidUserPlanAction

const isPaidUserPlan = (
  state: IsPaidUserPlanState = defaultPaidUserPlanState,
  action: PaidUserPlanAction
): IsPaidUserPlanState => {
  switch (action.type) {
    case SET_IS_PAID_USER_PLAN:
      return action.isPaidUserPlan
    default:
      return state
  }
}

export type IsPaidUserPlanState = boolean

export const defaultPaidUserPlanState = false

export default isPaidUserPlan
