import { createSelector } from 'reselect'

import { TokenChartSettings } from '../../model'
import { State } from '../index'
import { DRAFT_KEY, MultichartGroup } from './resources'

export const findActiveIndex = (
  items: MultichartGroup[] | TokenChartSettings[],
  uuid: string
): number => {
  const selectedIndex = items.findIndex(
    (item: MultichartGroup | TokenChartSettings) => item.uuid === uuid
  )

  return selectedIndex
}

export const findActiveGroup = (
  groups: MultichartGroup[],
  activeGroup: string
): MultichartGroup | undefined => {
  const selected = groups.find((group: MultichartGroup) => group.uuid === activeGroup)

  return selected
}

export const selectActiveGroup = createSelector(
  (state: State) => state.multichart.groups,
  (state: State) => state.multichart.activeGroup,
  findActiveGroup
)

export const selectIsDraft = createSelector(
  (state: State) => state.multichart.groups,
  (groups: MultichartGroup[]) => {
    if (groups.length === 1 && groups[0].uuid === DRAFT_KEY) {
      return true
    }

    return false
  }
)
