import { LimitOrder } from '../model/limitOrderModel'

export enum LimitOrdersActionTypes {
  initFetchLimitOrders = 'INIT_FETCH_LIMIT_ORDERS',
  doneFetchLimitOrders = 'DONE_FETCH_LIMIT_ORDERS',
  failFetchLimitOrders = 'FAIL_FETCH_LIMIT_ORDERS',
  setLimitOrders = 'SET_LIMIT_ORDERS',
}

export interface InitFetchLimitOrdersAction {
  type: LimitOrdersActionTypes.initFetchLimitOrders
}

export interface FailFetchLimitOrdersAction {
  type: LimitOrdersActionTypes.failFetchLimitOrders
  payload: string
}

export interface SetLimitOrdersAction {
  type: LimitOrdersActionTypes.setLimitOrders
  payload: { limitOrders: LimitOrder[]; error?: string }
}

export type LimitOrdersAction =
  | InitFetchLimitOrdersAction
  | FailFetchLimitOrdersAction
  | SetLimitOrdersAction

export type LimitOrdersState = {
  limitOrders: LimitOrder[]
  isLoading: boolean
  error?: string
}

export const defaultState: LimitOrdersState = {
  limitOrders: [],
  isLoading: false,
}

const limitOrders = (
  state: LimitOrdersState = defaultState,
  action: LimitOrdersAction
): LimitOrdersState => {
  switch (action.type) {
    case LimitOrdersActionTypes.initFetchLimitOrders:
      return { ...state, isLoading: true, error: undefined }
    case LimitOrdersActionTypes.failFetchLimitOrders:
      return { ...state, isLoading: false, error: action.payload }
    case LimitOrdersActionTypes.setLimitOrders:
      return {
        ...state,
        limitOrders: action.payload.limitOrders,
        isLoading: false,
        error: undefined,
      }
    default:
      return state
  }
}

export default limitOrders
