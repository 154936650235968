export const SET_IS_AUTH = 'SET_IS_AUTH'

export interface SetIsAuthAction {
  type: 'SET_IS_AUTH'
  isAuth: boolean
}

export type AuthAction = SetIsAuthAction

const isAuth = (state: IsAuthState = defaultIsAuthState, action: AuthAction): IsAuthState => {
  switch (action.type) {
    case SET_IS_AUTH:
      return action.isAuth
    default:
      return state
  }
}

export type IsAuthState = boolean | null | undefined

export const defaultIsAuthState = null

export default isAuth
