import { ChartDataV3 } from '../model'

export const SET_DATA_CHART = 'SET_DATA_CHART'

export interface SetDataChartAction {
  type: 'SET_DATA_CHART'
  dataChart: ChartDataV3
}

export type DataChartAction = SetDataChartAction

const dataChart = (
  state: DataChartState = defaultDataChartState,
  action: DataChartAction
): DataChartState => {
  switch (action.type) {
    case SET_DATA_CHART:
      return action.dataChart
    default:
      return state
  }
}

export const defaultDataChartState = []

export type DataChartState = ChartDataV3

export default dataChart
