import { NetworkConfigV3 } from '../model'

export const SET_NETWORKS_CONFIG = 'SET_NETWORKS_CONFIG'
export const NETWORKS_CONFIG_LOADED = 'NETWORKS_CONFIG_LOADED'

export interface SetNetworksConfigAction {
  type: 'SET_NETWORKS_CONFIG'
  payload: NetworkConfigV3[]
}
export interface NetworksConfigLoadedAction {
  type: typeof NETWORKS_CONFIG_LOADED
  payload: boolean
}
export interface NetworkConfigState {
  data: NetworkConfigV3[]
  isLoaded: boolean
  error: string
  lastUpdate?: number
}

export const defaultNetworksConfigState = { data: [], isLoaded: false, error: '' }

const networksConfig = (
  state: NetworkConfigState = defaultNetworksConfigState,
  action: SetNetworksConfigAction | NetworksConfigLoadedAction
): NetworkConfigState => {
  switch (action.type) {
    case SET_NETWORKS_CONFIG: {
      return { ...state, data: action.payload, lastUpdate: new Date().getTime(), isLoaded: true }
    }
    case NETWORKS_CONFIG_LOADED: {
      return { ...state, isLoaded: action.payload, lastUpdate: new Date().getTime() }
    }
    default:
      return state
  }
}

export default networksConfig
