import { TradeType } from '../model'

export const SET_ACTIVE_TRADE_TYPE = 'SET_ACTIVE_TRADE_TYPE'

export interface SetActiveTradeTypeAction {
  type: 'SET_ACTIVE_TRADE_TYPE'
  activeTradeType: ActiveTradeTypeState
}

export type TradeTypeAction = SetActiveTradeTypeAction

const activeTradeType = (
  state: ActiveTradeTypeState = defaultActiveTradeTypeState,
  action: TradeTypeAction
): ActiveTradeTypeState => {
  switch (action.type) {
    case SET_ACTIVE_TRADE_TYPE:
      return action.activeTradeType
    default:
      return state
  }
}

export const defaultActiveTradeTypeState = 'Buy'

export type ActiveTradeTypeState = TradeType

export default activeTradeType
