import { TransactionModel } from '../model'

export const SET_POOL_ACTIVITY = 'SET_POOL_ACTIVITY'
export const RESET_POOL_ACTIVITY = 'RESET_POOL_ACTIVITY'

type PoolActivityAction = SetPoolActivityAction | ResetPoolActivityAction

export interface SetPoolActivityAction {
  type: 'SET_POOL_ACTIVITY'
  currentPoolActivity?: TransactionModel[]
}

export interface ResetPoolActivityAction {
  type: 'RESET_POOL_ACTIVITY'
}

const poolActivity = (
  state = defaultPoolActivityState,
  action: PoolActivityAction
): PoolActivityState => {
  switch (action.type) {
    case SET_POOL_ACTIVITY:
      return {
        currentPoolActivity: action.currentPoolActivity,
        previousPoolActivity: state.currentPoolActivity,
      }
    case RESET_POOL_ACTIVITY:
      return defaultPoolActivityState
    default:
      return state
  }
}

export const defaultPoolActivityState: PoolActivityState = {
  currentPoolActivity: [],
  previousPoolActivity: [],
}

export type PoolActivityState = {
  currentPoolActivity?: TransactionModel[]
  previousPoolActivity?: TransactionModel[]
}

export default poolActivity
