import { PoolsData } from '../model'

export const SET_POOLS = 'SET_POOLS'

export type PoolsState = PoolsData

export interface SetPoolsAction {
  type: 'SET_POOLS'
  pools: PoolsState
}

export type PoolsAction = SetPoolsAction
export const defaultPoolsState = []

const pools = (state: PoolsState = defaultPoolsState, action: PoolsAction): PoolsState => {
  switch (action.type) {
    case SET_POOLS: {
      return action.pools
    }
    default:
      return state
  }
}

export default pools
