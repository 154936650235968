export const SET_IS_MOBILE = 'SET_IS_MOBILE'

export interface SetIsMobileAction {
  type: 'SET_IS_MOBILE'
  isMobile: boolean
}

export type MobileAction = SetIsMobileAction

const isMobile = (
  state: IsMobileState = defaultIsMobileState,
  action: MobileAction
): IsMobileState => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return action.isMobile
    default:
      return state
  }
}

export type IsMobileState = boolean | null

export const defaultIsMobileState = null

export default isMobile
