import { Currency } from '../model'

export const SET_CURRENCY = 'SET_CURRENCY'

export interface SetCurrencyAction {
  type: 'SET_CURRENCY'
  currency: Currency
}

export type CurrencyAction = SetCurrencyAction

const currency = (state: Currency = defaultCurrencyState, action: CurrencyAction): Currency => {
  switch (action.type) {
    case SET_CURRENCY:
      return action.currency
    default:
      return state
  }
}

export const defaultCurrencyState = 'USD'

export default currency
