import produce from 'immer'
import { v4 as uuid } from 'uuid'

import { TokenChartSettings } from '../../model'
import { findActiveIndex } from '.'
import {
  defaultDraftGroupState,
  defaultMultichartState,
  DRAFT_KEY,
  DROP_MULTICHART,
  MULTICHART_ADD_CHART,
  MULTICHART_ADD_GROUP,
  MULTICHART_DELETE_GROUP,
  MULTICHART_REMOVE_CHART,
  MULTICHART_SELECT_GROUP,
  MULTICHART_UPDATE_CHART,
  MULTICHART_UPDATE_GROUP,
  MultichartAction,
  MultichartState,
  RESTORE_MULTICHART,
  SET_ACTIVE_MULTICHART,
  SET_LOADED_MULTICHART,
} from './resources'

const multichart = (
  state: MultichartState = defaultMultichartState,
  action: MultichartAction
): MultichartState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ACTIVE_MULTICHART: {
        const { isActive } = action.payload

        if (!isActive) {
          draft = { ...defaultMultichartState }
        }

        draft.isActive = isActive

        break
      }

      case MULTICHART_ADD_GROUP: {
        const groupUuid =
          !action.payload?.name || action.payload?.name === DRAFT_KEY ? DRAFT_KEY : uuid()
        const group = { ...defaultDraftGroupState, ...action.payload, uuid: groupUuid }
        draft.groups.push(group)
        draft.activeGroup = groupUuid

        break
      }

      case MULTICHART_UPDATE_GROUP: {
        const index = findActiveIndex(state.groups, state.activeGroup)
        draft.groups[index] = { ...draft.groups[index], ...action.payload }
        draft.activeGroup = action.payload?.uuid || draft.groups[index].uuid

        break
      }

      case MULTICHART_DELETE_GROUP: {
        const index = findActiveIndex(state.groups, action.payload || state.activeGroup)
        draft.groups.splice(index, 1)

        if (draft.groups.length) {
          draft.activeGroup = draft.groups[0].uuid
        } else {
          draft.activeGroup = ''
        }

        break
      }

      case MULTICHART_SELECT_GROUP: {
        draft.activeGroup = action.payload

        break
      }

      case MULTICHART_ADD_CHART: {
        const index = findActiveIndex(state.groups, state.activeGroup)
        draft.groups[index].charts.push(action.payload)

        break
      }

      case MULTICHART_REMOVE_CHART: {
        const index = findActiveIndex(state.groups, state.activeGroup)

        draft.groups[index].charts = draft.groups[index].charts.filter(
          (chart: TokenChartSettings) => chart.uuid !== action.payload
        )

        if (!draft.groups[index].charts.length) {
          if (draft.groups.length === 1) {
            draft.activeGroup = ''
            draft.groups = []
          } else {
            draft.activeGroup = draft.groups[0].uuid
          }
        }

        break
      }

      case MULTICHART_UPDATE_CHART: {
        const groupIndex = findActiveIndex(state.groups, state.activeGroup)
        const index = findActiveIndex(draft.groups[groupIndex].charts, action.payload.uuid || '')

        draft.groups[groupIndex].charts[index] = action.payload

        break
      }

      case RESTORE_MULTICHART: {
        draft.groups = action.payload.groups
        draft.activeGroup = action.payload.groups[0]?.uuid || ''

        break
      }

      case SET_LOADED_MULTICHART: {
        draft.isLoaded = action.payload.isLoaded

        break
      }

      case DROP_MULTICHART: {
        return { ...defaultMultichartState }
      }

      default:
        break
    }
  })

export default multichart
