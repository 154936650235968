const INTRO_DONE_KEY = 'onboarding-intro-completed'

export const SET_IS_ONBOARDING_VISIBLE = 'SET_IS_ONBOARDING_VISIBLE'
export const INIT_IS_ONBOARDING_VISIBLE = 'INIT_IS_ONBOARDING_VISIBLE'

export interface SetIsOnboardingVisibleAction {
  type: 'SET_IS_ONBOARDING_VISIBLE'
  isVisible?: boolean
}

export interface InitIsOnboardingVisibleAction {
  type: 'INIT_IS_ONBOARDING_VISIBLE'
}

type OnboardingAction = SetIsOnboardingVisibleAction | InitIsOnboardingVisibleAction

const isOnboardingVisible = (
  state: OnboardingState = defaultOnboardingState,
  action: OnboardingAction
): OnboardingState => {
  switch (action.type) {
    case SET_IS_ONBOARDING_VISIBLE: {
      localStorage.setItem(INTRO_DONE_KEY, (!action.isVisible).toString())
      return !!action.isVisible
    }
    case INIT_IS_ONBOARDING_VISIBLE: {
      const wasViewed = localStorage.getItem(INTRO_DONE_KEY)
      return wasViewed !== 'true'
    }
    default:
      return state
  }
}

export type OnboardingState = boolean | null

export const defaultOnboardingState = null

export default isOnboardingVisible
