import { ApiError } from '../helpers/ProxyApiFetch/types'
import { Alert, LastNotification, Notification } from '../model'

export enum NotificationsActionTypes {
  startFetchAlerts = 'START_FETCH_ALERTS',
  successFetchAlerts = 'SUCCESS_FETCH_ALERTS',
  failFetchAlerts = 'FAIL_FETCH_ALERTS',
  setAlerts = 'SET_ALERTS',
  startAddAlert = 'START_ADD_ALERT',
  successAddAlert = 'SUCCESS_ADD_ALERT',
  deleteAlert = 'DELETE_ALERT',
  startFetchNotifications = 'START_FETCH_NOTIFICATIONS',
  successFetchNotifications = 'SUCCESS_FETCH_NOTIFICATIONS',
  failFetchNotifications = 'FAIL_FETCH_NOTIFICATIONS',
  addNotifications = 'ADD_NOTIFICATIONS',
  resetToDefaults = 'NOTIFICATIONS_RESET_TO_DEFAULTS', // for test reset
  addNotification = 'ADD_NOTIFICATION',
  addLastNotification = 'ADD_LAST_NOTIFICATION',
  setNotifications = 'SET_NOTIFICATIONS',
}

export interface ResetNotificationsState {
  type: NotificationsActionTypes.resetToDefaults
}

export interface SetAlertsAction {
  type: NotificationsActionTypes.setAlerts
  payload: Alert[]
}

export interface StartAddAlertAction {
  type: NotificationsActionTypes.startAddAlert
}

export interface StartFetchAlertsAction {
  type: NotificationsActionTypes.startFetchAlerts
}

export type SuccessFetchAlertsAction = {
  type: NotificationsActionTypes.successFetchAlerts
}

export type SuccessAddAlertAction = {
  type: NotificationsActionTypes.successAddAlert
}

export interface FailFetchAlertsAction {
  type: NotificationsActionTypes.failFetchAlerts
  payload?: ApiError
}

export interface DeleteAlertAction {
  type: NotificationsActionTypes.deleteAlert
  payload: string
}

export interface SetNotificationsAction {
  type: NotificationsActionTypes.setNotifications
  payload: {
    notifications?: Notification[]
    last?: LastNotification
  }
}
export interface StartFetchNotificationsAction {
  type: NotificationsActionTypes.startFetchNotifications
}

export type SuccessFetchNotificationsAction = {
  type: NotificationsActionTypes.successFetchNotifications
}

export interface FailFetchNotificationsAction {
  type: NotificationsActionTypes.failFetchNotifications
  payload?: ApiError
}

export interface AddNotificationAction {
  type: NotificationsActionTypes.addNotification
  payload: Notification
}

export interface AddLastNotificationAction {
  type: NotificationsActionTypes.addLastNotification
  payload: LastNotification
}

export type NotificationsAction =
  | ResetNotificationsState
  | SetAlertsAction
  | StartFetchAlertsAction
  | StartAddAlertAction
  | SuccessFetchAlertsAction
  | SuccessAddAlertAction
  | FailFetchAlertsAction
  | DeleteAlertAction
  | SetNotificationsAction
  | StartFetchNotificationsAction
  | SuccessFetchNotificationsAction
  | FailFetchNotificationsAction
  | AddNotificationAction
  | AddLastNotificationAction

export type NotificationsState = {
  alerts: Alert[]
  notifications: Notification[]
  lastNotification?: LastNotification
  isNotificationsLoading: boolean
  isAlertsLoading: boolean
  error: null | ApiError
}

export const defaultState: NotificationsState = {
  alerts: [],
  notifications: [],
  isNotificationsLoading: false,
  isAlertsLoading: false,
  error: null,
}

const notifications = (
  state: NotificationsState = defaultState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case NotificationsActionTypes.startFetchAlerts:
    case NotificationsActionTypes.startAddAlert:
      return { ...state, isAlertsLoading: true }
    case NotificationsActionTypes.successFetchAlerts:
    case NotificationsActionTypes.successAddAlert:
      return { ...state, isAlertsLoading: false }
    case NotificationsActionTypes.failFetchAlerts:
      return { ...state, isAlertsLoading: false }
    case NotificationsActionTypes.setAlerts:
      return { ...state, alerts: action.payload }
    case NotificationsActionTypes.startFetchNotifications:
      return { ...state, isNotificationsLoading: true }
    case NotificationsActionTypes.successFetchNotifications:
      return { ...state, isNotificationsLoading: false }
    case NotificationsActionTypes.setNotifications:
      return {
        ...state,
        notifications: action.payload.notifications || state.notifications,
        lastNotification: action.payload.last,
      }
    case NotificationsActionTypes.addNotification:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      }
    case NotificationsActionTypes.addLastNotification: {
      return {
        ...state,
        lastNotification: action.payload,
      }
    }
    case NotificationsActionTypes.deleteAlert:
      return {
        ...state,
        alerts: state.alerts.filter((a) => a.id !== action.payload),
      }
    case NotificationsActionTypes.failFetchNotifications:
      return { ...state, isNotificationsLoading: false }
    case NotificationsActionTypes.resetToDefaults:
      return defaultState
    default:
      return state
  }
}

export default notifications
